<template>
  <div class="container">
    <!-- <router-link to="/register">注册</router-link>
    <router-link to="/manager">管理员端</router-link>
    <router-link to="/followPerson">跟进人员端</router-link>
    <router-link to="/platform">平台</router-link> -->
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
  watch: {},
};
</script>
<style lang="scss" scoped>
.container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  a {
    text-align: center;
    line-height: 40px;
  }
}
</style>